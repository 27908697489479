var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "mt-4",
                  attrs: { header: "Label - " + this.title }
                },
                [
                  _vm.saving
                    ? _c("div", [_c("b-spinner"), _vm._v(" Opslaan... ")], 1)
                    : _vm._e(),
                  !_vm.saving
                    ? _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _vm._l(_vm.form.fields, function(field, name) {
                            return _c("form-field", {
                              key: name,
                              attrs: {
                                field: field,
                                form: _vm.form,
                                name: name
                              }
                            })
                          }),
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Opslaan")]
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }