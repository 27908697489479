<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="'Label - ' + this.title" class="mt-4">
          <div v-if="saving">
            <b-spinner /> Opslaan...
          </div>
          
          <b-form v-if="!saving" @submit.prevent="onSubmit">
            <form-field v-for="(field, name) in form.fields" :field="field" :key="name" :form="form" :name="name" /> 

            <b-button type="submit" variant="primary">Opslaan</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import FormField from '@/components/FormField'

  export default {
    name: "LabelDetailEdit",
    components: {
      FormField,
    },
    data() {
      return {
        title: '',
        saving: false,
        form: {
          values: null,
          validated: false,
          fields: {
            "Name": {
              label: "Naam",
              type: this.id == 'new' ? "text" : "readonly",

            },
            "ContractID": {
              label: "Contract",
              type: "readonly"
            },
            "ParentLabelID": {
              label: "Parent Label",
              type: "readonly"
            },
            "Status": {
              label: "Status",
              type: "select",
              required: true,
              options: [
                { value: 'active', text: 'Actief' },
                { value: 'inactive', text: 'Inactief' },
                { value: 'deleted', text: 'Verwijderd' },
              ]
            },
            "RoyaltyPerc": {
              label: "Royalty",
              type: "text"
            },
            "RoyaltyPercStreaming": {
              label: "Royalty Streaming",
              type: "text"
            }
          }
        }  
      };
    },
    props: {
      id: String,
      label: Object,
    },
    watch: {
    },
    methods: {
      onSubmit () {
        this.saving = true

        if (this.label) {
          this.$http.put('labels/'+this.label.LabelID, this.form.values)
            .then((response) => {
              this.$emit('refresh')
              this.$router.push({ name: 'label-detail', params: { id: response.data.LabelID } })
            })
            .catch((error) => {
              console.log(error)
            });

        } else {
          this.$http.post('labels', this.form.values)
            .then((response) => {
              this.$emit('refresh')
              this.$router.push({ name: 'label-detail', params: { id: response.data.LabelID } })
            })
            .catch((error) => {
              console.log(error)
            });
        }
      }
    },
    mounted() {
      if (this.label == null) {
        this.title = 'Nieuw'
        this.$set(this.form, 'values', {
          ContractID: this.$route.params.ContractID,
          ParentLabelID: this.$route.params.ParentLabelID,
        })
      } else {
        this.title = this.label.Name + ' (' + this.label.LabelID + ')'
        this.$set(this.form, 'values', {...this.label})
      }

      this.$root.$data.crumbs = [
        { to: { name: "label-search" }, text: "Labels" },
        { text: this.title },
      ];
    }
  };
</script>

<style lang="scss" scoped>
  .track {
    .card-header {
      color: black;
      background-color: #acbcc8;
    }
  }
</style>